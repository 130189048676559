export const generateValues = () => {
  const allCapsAlpha = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
  const allLowerAlpha = [...'abcdefghijklmnopqrstuvwxyz'];
  const allUniqueChars = [...'-_'];
  const allNumbers = [...'0123456789'];

  const base = [...allCapsAlpha, ...allNumbers, ...allLowerAlpha, ...allUniqueChars];
  const strLength = Math.floor(Math.random() * 8) + 8;
  return [...Array(strLength)]
    .map(() => base[Math.floor(Math.random() * base.length)])
    .join('');
};
