<template>
  <div>
    <v-container fluid>
      <div class="d-flex justify-end">
        <v-btn outlined color="darkblue" @click="showEditDialog = true">Inviter un utilisateur</v-btn>
      </div>

      <Table
        :headers="headers"
        :items="items"
        isUserTable
        @onDelete="showDeleteDialog"
        @onEdit="openEditDialog"
        :loading="this.isLoading"
      />
    </v-container>

    <v-dialog v-model="showEditDialog" width="500">
      <v-card>
        <v-card-title>{{ form.newUser ? 'Inviter un ' : "Éditer l'" }} utilisateur</v-card-title>

        <v-card-text>
          <v-alert v-if="errorMessage" type="error">
            {{ errorMessage }}
          </v-alert>
          <v-form ref="form" v-model="isValid" lazy-validation>
            <v-text-field
              v-model="form.email"
              outlined
              dense
              label="Courriel"
              :rules="[rules.required, rules.email]"
              @focus="errorMessage = null"
            />
            <v-text-field
              v-model="form.password"
              outlined
              dense
              label="Mot de passe"
              v-if="form.newUser"
              :rules="[rules.required, rules.minEight, rules.validChar]"
              maxlength="16"
              :append-icon="loadIcon"
              @click:append="generatePassword"
            />
            <v-text-field v-model="form.firstName" outlined dense label="Prénom" :rules="[rules.required]" />
            <v-text-field v-model="form.lastName" outlined dense label="Nom de famille" :rules="[rules.required]" />
            <v-text-field
              v-model="form.phoneNumber"
              outlined
              dense
              label="Téléphone"
              type="text"
              v-mask="mask"
              :rules="[rules.required, rules.min]"
            />
            <v-text-field
              v-model="form.referenceNumber"
              outlined
              dense
              label="Numéro de dossier"
              type="text"
              :rules="[rules.maxTwenty]"
            />
            <v-select
              v-model="form.role"
              :rules="[rules.required]"
              :items="
                this.$store.state.auth.claims.superAdmin || this.$store.state.auth.claims.eyeStaffAdmin
                  ? [
                      { code: 'eyeStaffAdmin', desc: 'Administrateur EyeStaff' },
                      { code: 'eyeStaff', desc: 'Administrateur EyeNation' },
                      { code: 'orgAdmin', desc: 'Administrateur de l\'organisation' },
                      { code: 'orgUser', desc: 'Utilisateur de l\'organisation' }
                    ]
                  : this.$store.state.auth.claims.eyeStaff
                  ? [
                      { code: 'eyeStaff', desc: 'Administrateur EyeNation' },
                      { code: 'orgAdmin', desc: 'Administrateur de l\'organisation' },
                      { code: 'orgUser', desc: 'Utilisateur de l\'organisation' }
                    ]
                  : [{ code: 'orgUser', desc: 'Utilisateur de l\'organisation' }]
              "
              item-text="desc"
              item-value="code"
              label="Role"
              outlined
              dense
              :disabled="form.isDefault"
              v-if="
                this.$store.state.auth.claims.superAdmin ||
                this.$store.state.auth.claims.eyeStaff ||
                this.$store.state.auth.claims.orgAdmin ||
                this.$store.state.auth.claims.eyeStaffAdmin
              "
            />
            <v-select
              v-model="form.organization"
              :rules="[rules.required]"
              :items="organizations"
              item-text="name"
              item-value="id"
              label="Organisation"
              outlined
              dense
              v-if="
                  (this.$store.state.auth.claims.superAdmin ||
                  this.$store.state.auth.claims.eyeStaff ||
                  this.$store.state.auth.claims.eyeStaffAdmin) &&
                  (form.role === 'orgUser' || form.role === 'orgAdmin')
                "
            />
            <v-checkbox
              v-model="form.needSubscription"
              label="Abonnement requis"
            ></v-checkbox>
            <v-container v-if="!form.newUser && deviceList.length > 0">
              <v-divider></v-divider>
              <v-subheader>Dispositifs</v-subheader>

              <Table :headers="deviceheaders" :items="deviceList" isUserDeviceTable :loading="this.isLoading" />
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn :loading="isLoading" :disabled="isLoading" color="secondary" text @click="onSubmitForm"> Sauvegarder </v-btn>
          <v-btn color="error" text @click="showEditDialog = false">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mdiCached } from '@mdi/js';
import sortBy from 'lodash.sortby';
import Table from '@/components/Admin/Table';
import { generateValues } from '../../utils/stringGenerator';

export default {
  data: () => ({
    headers: [
      { class: 'caption darkblue--text', text: 'Nom de famille', value: 'lastName' },
      { class: 'caption darkblue--text', text: 'Prénom', value: 'firstName' },
      { class: 'caption darkblue--text', text: 'Courriel', value: 'email' },
      { class: 'caption darkblue--text', text: 'Type d\'appareil', value: 'deviceType' },
      { class: 'caption darkblue--text', text: 'Téléphone', value: 'phoneNumber' },
      { class: 'caption darkblue--text', text: 'Organisation', value: 'organization' },
      { class: 'caption darkblue--text', text: '# Dossier', value: 'referenceNumber' },
      { class: 'caption darkblue--text', text: 'Role', value: 'role' },
      { class: 'caption darkblue--text', text: 'Dispositifs', value: 'devices' },
      { text: '', value: 'actions', align: 'center' },
    ],
    showEditDialog: false,
    isValid: true,
    form: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      referenceNumber: '',
      newUser: true,
      role: '',
      needSubscription: false,
    },
    rules: {
      required: (v) => !!v || 'Ce champ est requis',
      email: (v) => /.+@.+\..+/.test(v) || 'Le courriel doit être valide',
      min: (v) => {
        const pattern = /^\d{3}-?\d{3}-?\d{4}$/;
        return pattern.test(v) || 'Minimum 10 caractères';
      },
      minEight: (v) => v.length >= 8 || 'Minimum 8 caractères',
      maxTwenty: (v) => {
        let res = true;
        if (v) {
          res = v.length <= 20 || 'Maximum 20 caractères';
        }
        return res;
      },
      validChar: (v) => /^[a-zA-Z0-9-_"]{8,}$/.test(v) || 'Doit avoir une combinaison de chiffres, lettres et caractères spéciaux (_ or -)',
      letter: (v) => /(?=.*[a-zA-Z])/.test(v) || 'Doit avoir une lettre',
      number: (v) => /(?=.*[0-9])/.test(v) || 'Doit avoir un chiffre',
      specialChar: (v) => /(?=.*[-_].*[-_])/.test(v) || 'Doit avoir au minimum 2 caractères spéciaux (- or _)',
    },
    mask: '###-###-####',
    loadIcon: mdiCached,
    deviceheaders: [
      { class: 'caption darkblue--text', text: 'ID', value: 'deviceId' },
      { class: 'caption darkblue--text', text: 'Type de dispositif', value: 'deviceType' },
    ],
    deviceList: '',
    errorMessage: null,
  }),
  components: {
    Table,
  },
  mounted() {
    if (!this.$store.state.organizations.isInitialized) {
      this.$store.dispatch('organizations/getOrganizations');
    }

    if (!this.$store.state.users.isInitialized) {
      this.$store.dispatch('users/getUsers');
      this.$store.dispatch('users/getOwnerList'); // for owner dropdown in devices
    }

    if (!this.$store.state.devices.isInitialized) {
      this.$store.dispatch('devices/getDevices');
      this.$store.dispatch('devices/getDevicesForCount'); // for devices count
    }
  },
  computed: {
    ...mapState('auth', ['role']),
    ...mapState('users', ['users', 'isLoading']),
    ...mapState('organizations', ['organizations']),
    items() {
      return sortBy(this.users, [(user) => `${user.lastName.toLowerCase()}, ${user.firstName.toLowerCase()}`]);
    },
  },
  methods: {
    async showDeleteDialog(payload) {
      if (payload.role !== this.role) {
        const devices = this.$store.state.devices.devices.filter((device) => device.owner === payload.id);
        const appendMessage = devices.length > 0 ? ` Tous les dispositifs (${devices.length}) vont aussi être effacés.` : '';
        const confirm = await this.$root.$confirmDiaglog(
          'Confirmation',
          `Être-vous certain de vouloir effacer cet utilisateur? ${appendMessage}`,
        );
        if (confirm) {
          await this.$store.dispatch('users/deleteUser', { payload });
        }
      }
    },
    openEditDialog(item) {
      if (item.role !== this.role) {
        this.form = { ...item, newUser: false };
        this.deviceList = this.$store.state.devices.devices.filter((device) => device.owner === item.id);
        this.showEditDialog = true;
      }
    },
    numericOnly(pn) {
      return pn.replace(/\D/g, '');
    },
    async onSubmitForm() {
      if (this.$refs.form.validate()) {
        if (!this.$store.state.auth.claims.superAdmin && !this.$store.state.auth.claims.eyeStaff) {
          this.form.organization = this.$store.state.organizations.userOrganization.id;
        }
        this.form.phoneNumber = this.numericOnly(this.form.phoneNumber);
        await this.$store.dispatch('users/submitUser', { payload: this.form });
        if (!this.$store.state.users.error) {
          this.showEditDialog = false;
          this.errorMessage = null;
        } else {
          this.errorMessage = this.$store.state.users.error;
        }
      }
    },
    generatePassword() {
      this.form.password = generateValues();
    },
  },
  watch: {
    showEditDialog(val) {
      if (!val) {
        this.form = {
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          referenceNumber: '',
          newUser: true,
          needSubscription: false,
        };
        this.errorMessage = null;
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>
